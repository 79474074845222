import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRandomPrompt } from '../lib/prompts';
import { addJournalEntry } from '../lib/journal';
import styles from './PromptForm.module.css';

function PromptForm() {
  const [prompt, setPrompt] = useState(getRandomPrompt());
  const [response, setResponse] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addJournalEntry(prompt.text, response);
      setResponse('');
      setPrompt(getRandomPrompt());
      
      toast.success(
        ({ closeToast }) => (
          <div>
            Entry saved successfully!
            <button 
              onClick={() => {
                closeToast();
                navigate('/journal-entries');
              }} 
              className={styles.toastButton}
            >
              View Entries
            </button>
          </div>
        ),
        {
          autoClose: 5000, // Close after 5 seconds if button is not clicked
          closeOnClick: false,
        }
      );
    } catch (error) {
      console.error('Error saving journal entry:', error);
      toast.error('Failed to save entry. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.promptForm}>
      <div className={styles.promptCard}>
        <h2 className={styles.promptText}>{prompt.text}</h2>
      </div>
      <textarea
        className={styles.responseInput}
        value={response}
        onChange={(e) => setResponse(e.target.value)}
        placeholder="Write your response here..."
        rows="5"
      />
      <button type="submit" className={styles.submitButton}>Save Entry</button>
    </form>
  );
}

export default PromptForm;