import React, { useState, useEffect } from 'react';
import { getJournalEntries, toggleEntryLike } from '../lib/journal';
import styles from './JournalEntries.module.css';

function JournalEntries() {
  const [entries, setEntries] = useState([]);
  const [activeTab, setActiveTab] = useState('all');

  useEffect(() => {
    fetchEntries();
  }, []);

  const fetchEntries = async () => {
    try {
      const fetchedEntries = await getJournalEntries();
      const sortedEntries = fetchedEntries.sort((a, b) => new Date(b.date) - new Date(a.date));
      setEntries(sortedEntries);
    } catch (error) {
      console.error('Error fetching journal entries:', error);
    }
  };

  const handleLikeToggle = async (id) => {
    try {
      await toggleEntryLike(id);
      fetchEntries(); // Refresh entries after toggling like
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  const filteredEntries = activeTab === 'all' ? entries : entries.filter(entry => entry.liked);

  return (
    <div>
      <h2>Journal Entries</h2>
      <div className={styles.tabs}>
        <button
          className={`${styles.tab} ${activeTab === 'all' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('all')}
        >
          All
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'liked' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('liked')}
        >
          Liked
        </button>
      </div>
      <div className={styles.entriesContainer}>
        {filteredEntries.map(entry => (
          <div key={entry.id} className={styles.entryCard}>
            <div className={styles.entryDate}>
              {new Date(entry.date).toLocaleString()}
            </div>
            <div className={styles.entryPrompt}>
              <strong>Prompt:</strong> {entry.prompt}
            </div>
            <div className={styles.entryResponse}>
              {entry.response}
            </div>
            <button
              className={`${styles.likeButton} ${entry.liked ? styles.liked : ''}`}
              onClick={() => handleLikeToggle(entry.id)}
            >
              {entry.liked ? '❤️' : '🤍'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default JournalEntries;