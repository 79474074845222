let db;

const DB_NAME = 'JournalDB';
const DB_VERSION = 1;
const STORE_NAME = 'entries';

export const initDb = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => reject(`IndexedDB error: ${event.target.error}`);

    request.onsuccess = (event) => {
      db = event.target.result;
      console.log('Database initialized successfully');
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        const objectStore = db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
        objectStore.createIndex('date', 'date', { unique: false });
        objectStore.createIndex('liked', 'liked', { unique: false });
        console.log('Object store created');
      }
    };
  });
};

export const saveEntry = (entry) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.add(entry);

    request.onerror = (event) => reject(`Error saving entry: ${event.target.error}`);
    request.onsuccess = (event) => {
      console.log('Entry saved successfully');
      resolve(event.target.result);
    };
  });
};

export const getAllEntries = () => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.getAll();

    request.onerror = (event) => reject(`Error fetching entries: ${event.target.error}`);
    request.onsuccess = (event) => {
      console.log('Entries fetched successfully');
      resolve(event.target.result);
    };
  });
};

export const getEntryById = (id) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(id);

    request.onerror = (event) => reject(`Error fetching entry: ${event.target.error}`);
    request.onsuccess = (event) => {
      console.log('Entry fetched successfully');
      resolve(event.target.result);
    };
  });
};

export const updateEntry = (entry) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.put(entry);

    request.onerror = (event) => reject(`Error updating entry: ${event.target.error}`);
    request.onsuccess = (event) => {
      console.log('Entry updated successfully');
      resolve(event.target.result);
    };
  });
};

export const deleteEntry = (id) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.delete(id);

    request.onerror = (event) => reject(`Error deleting entry: ${event.target.error}`);
    request.onsuccess = (event) => {
      console.log('Entry deleted successfully');
      resolve();
    };
  });
};

export const toggleLike = (id) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const getRequest = store.get(id);

    getRequest.onerror = (event) => reject(`Error fetching entry: ${event.target.error}`);
    getRequest.onsuccess = (event) => {
      const entry = event.target.result;
      entry.liked = !entry.liked;
      const updateRequest = store.put(entry);

      updateRequest.onerror = (event) => reject(`Error updating entry: ${event.target.error}`);
      updateRequest.onsuccess = (event) => {
        console.log('Entry like status updated successfully');
        resolve(entry);
      };
    };
  });
};