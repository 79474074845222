import React from 'react';
import PromptForm from './PromptForm';
import styles from './Home.module.css';

function Home() {
  return (
    <div className={styles.homeContainer}>
      <h1 className={styles.title}>Welcome to Your Journal</h1>
      <p className={styles.subtitle}>Reflect on today's prompt and write your thoughts.</p>
      <PromptForm />
    </div>
  );
}

export default Home;