import { saveEntry, getAllEntries, toggleLike } from './db';

export const addJournalEntry = async (prompt, response) => {
  const entry = {
    prompt,
    response,
    date: new Date().toISOString(),
    liked: false
  };

  try {
    const id = await saveEntry(entry);
    return { ...entry, id };
  } catch (error) {
    console.error('Error saving journal entry:', error);
    throw error;
  }
};

export const getJournalEntries = async () => {
  try {
    return await getAllEntries();
  } catch (error) {
    console.error('Error fetching journal entries:', error);
    throw error;
  }
};

export const toggleEntryLike = async (id) => {
  try {
    return await toggleLike(id);
  } catch (error) {
    console.error('Error toggling entry like:', error);
    throw error;
  }
};