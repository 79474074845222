import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './components/Home';
import JournalEntries from './components/JournalEntries';
import { initDb } from './lib/db';

function App() {
  const [dbInitialized, setDbInitialized] = useState(false);

  useEffect(() => {
    initDb()
      .then(() => {
        console.log('Database initialized');
        setDbInitialized(true);
      })
      .catch(error => console.error('Failed to initialize database:', error));
  }, []);

  if (!dbInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/journal-entries">Journal Entries</Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/journal-entries" element={<JournalEntries />} />
        </Routes>
        <ToastContainer position="bottom-right" />
      </div>
    </Router>
  );
}

export default App;